export const firebaseConfig = {
  apiKey: 'AIzaSyDZcjHGfjpcfiBZWfmgMtFL7KNabSl5zoI',
  authDomain: 'chainsure-dev.firebaseapp.com',
  databaseURL: 'https://chainsure-dev.firebaseio.com',
  projectId: 'chainsure-dev',
  storageBucket: 'chainsure-dev.appspot.com',
  messagingSenderId: '794558018456'
}

export const host = {
  url: 'https://dev.chainsure.de',
  rootApi: '/api',
  rootPages: '/pages'
}

export const social = {
  facebook: {
    appID: 1581349775497554,
    version: 'v3.2'
  },
  twitter: {
    account: 'PLIDScom'
  }
}

export const originstamp = {
  timestampUrl: 'https://originstamp.org/s/{{hash}}'
}

export const freeCurrencyConverter = {
  rootApi: 'https://free.currencyconverterapi.com/api/v6',
  apiKey: '1047d015658c825c204c'
}

export const cloudinary = {
  rootApi: 'https://api.cloudinary.com/v1_1/dmybuwhjm',
  cloudName: 'dmybuwhjm',
  unsignUploadPreset: 'hwxhocn8',
  apiKey: '498326366573643'
}
